<template>
  <div class="tinymceEditorBoxPage">
    <editor
      v-model="content"
      api-key="7v7rdf4ceh3lhjt15sp2qd3hj6l429x52rt1bpbazd83zy1i"
      :init="{
        height: 600,
        language: 'zh_CN',
        menubar: 'file edit insert view format table',
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
          'undo redo | image | formatselect | bold italic backcolor | \
                                             alignleft aligncenter alignright alignjustify | \
                                             bullist numlist outdent indent | removeformat | help',
        // 图片读取前缀路径
        images_upload_base_path: this.$fileUrl,
        // 图片本地上传方法  点击上传后执行的事件
        images_upload_handler: (blobInfo, success, failure) => {
          // eslint-disable-next-line vue/this-in-template
          this.handleImgUpload(blobInfo, success, failure);
        },
      }"
    />
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'
export default {
  name: 'App',
  components: {
    editor: Editor
  },
  props: {
    introductionContent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      content: ''
    }
  },
  watch: {
    introductionContent: {
      handler(newVal) {
        this.content = newVal
      },
      immediate: true,
      deep: true
    },
    content: {
      handler(newVal) {
        this.$emit('getIntroductionContent', newVal)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleImgUpload(blobInfo, success, failure) {
      const formdata = new FormData()
      // append 方法中的第一个参数就是 我们要上传文件 在后台接收的文件名
      // 这个值要根据后台来定义
      // 第二个参数是我们上传的文件
      formdata.append('file', blobInfo.blob())
      // axios 定义上传方法
      axios({
        method: 'post', // post方法
        url: this.$baseUpload, // 请求上传图片服务器的路径
        headers: {
          token: sessionStorage.getItem(`${sessionStorage.getItem('systemPlatformRoles') === '03' ? 'tokenCust' : 'tokenAgent'}`)
        },
        data: formdata // 请求数据formdata
      }).then((res) => {
        if (res.data.code !== 200) {
          // 上传失败执行此方法，将失败信息填入参数中
          failure('HTTP Error: ' + res.msg)
          return
        }
        // 上传成功之后，将对应完整的图片路径拼接在success的参数中
        success(this.$fileUrl + res.data.data)
      })
    }
  }
}
</script>

<style lang="scss">
.tinymceEditorBoxPage {
  .tox-tinymce-aux {
    z-index: 5000 !important;
  }
  .tox-notifications-container {
    display: none !important;
  }
  .tox-statusbar__branding {
    display: none;
  }
}
</style>
