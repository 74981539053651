<template>
  <!-- 合同模板管理 -->
  <div class="contractTemplate">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :reset="true" :search-form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="editAndAddRow()"
        >
          添加
        </el-button>
      </div>
      <Table :item-data="itemData" :list-data="listData" :operation-button="operationButton" :loading="loading" />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <!-- 弹出的新增跟修改框 -->
    <Dialog ref="dialog" :edit-form-data="editFormData" :add-and-edit-form-item="addAndEditFormItem" @getFormData="getFormData">
      <template slot="dynamicInsertAfter">
        <el-form-item label="合同内容" prop="compactContent">
          <TinymceEditor
            :introduction-content="editFormData.compactContent"
            @getIntroductionContent="getIntroductionContent"
          />
        </el-form-item>
      </template>
    </Dialog>
  </div>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import Dialog from '@/components/Dialog'
import FormSearch from '@/components/FormSearch'
import { timestampToTime } from '@/unit/index'
import { getCmpBaseinfoList } from '@/http/agentApi/common'
import { compactServicePage2cmp, compactServiceSaveAndUpdata, compactServiceDelete } from '@/http/agentApi/enterpriseInformation'
export default {
  components: { TinymceEditor, Table, Pagination, Dialog, FormSearch },
  data() {
    return {
      cmpNameArr: [{ cmpCode: '-1', cmpName: '全平台' }],
      editFormData: {},
      itemData: [
        { label: '企业名称', prop: 'cmpName', width: 180 },
        { label: '合同名称', prop: 'compactName', width: 180 },
        // { label: '合同内容', prop: 'compactContent', width: 180, html: true },
        { label: '合同类型', prop: 'compactType', width: 180, child: this.$store.getters.getDictionaryItem('compactType') },
        { label: '创建人', prop: 'createUser', width: 180 },
        { label: '创建时间', prop: 'createTime', width: 180 },
        { label: '修改人', prop: 'modifyUser', width: 180 },
        { label: '修改时间', prop: 'modifyTime', width: 180 }
      ],
      addAndEditFormItem: [
        { label: '合同名称', type: 'input', value: 'compactName' },
        { label: '方向', type: 'select', value: 'compactTypeset', optionLabel: 'dictName', optionId: 'dictId', child: [{ dictName: '竖向', dictId: 0 }, { dictName: '横向', dictId: 1 }] },
        { label: '合同类型', type: 'select', value: 'compactType', optionLabel: 'dictName', optionId: 'dictId', child: this.$store.getters.getDictionaryItem('compactType') }
      ],
      operationButton: [
        { num: '-1', val: 'cmpCode', negation: true, bType: 'primary', label: '编辑', handleEvent: this.editAndAddRow },
        { num: '-1', val: 'cmpCode', negation: true, bType: 'danger', label: '删除', handleEvent: this.deleteRow }
      ],
      formItemArr: [
        { type: 'input', label: '合同名称', value: 'compactName' },
        { type: 'select', label: '合同类型', value: 'compactType', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('compactType') }
      ],
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        cmpCode: JSON.parse(localStorage.getItem(`${sessionStorage.getItem('systemPlatformRoles') === '03' ? 'userInfoCust' : 'userInfoAgent'}`)).cmpId
      }
    }
  },
  methods: {
    getIntroductionContent(value) {
      this.editFormData.compactContent = value
    },
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      compactServicePage2cmp(this.formInline, res => {
        this.listData = [...res.data.list]
        this.listData.forEach(item => {
          item.modifyTime = timestampToTime(item.modifyTime)
          item.createTime = timestampToTime(item.createTime)
        })
        this.total = res.data.total
      })
    },
    getCmpName(e) {
      this.cmpNameArr.forEach(item => {
        if (item.cmpCode === e) {
          this.editFormData.cmpName = item.cmpName
        }
      })
    },
    // 联动搜搜货主列表
    remoteMethod(value) {
      if (!value) return
      getCmpBaseinfoList(value, res => {
        this.cmpNameArr = [{ cmpCode: '-1', cmpName: '全平台' }, ...res.data]
      })
    },
    // 新增或修改数据
    editAndAddRow(row) {
      this.$refs.dialog.editFormVisible = true
      this.editFormData = row ? { ...row } : { compactContent: '' }
      if (row) this.remoteMethod(row.cmpName)
    },
    // 启用与禁用
    deleteRow(row) {
      this.$confirm('此操作将删除此条信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        compactServiceDelete({ compactId: row.compactId }, res => {
          this.$message.success('成功！')
          this.getdata(true)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 获取到输入框的内容
    getFormData(value) {
      if (!this.editFormData.compactContent) {
        this.$message.error('请填写合同内容！')
        return
      }
      value.compactContent = this.editFormData.compactContent
      const userInfo = JSON.parse(localStorage.getItem(`${sessionStorage.getItem('systemPlatformRoles') === '03' ? 'userInfoCust' : 'userInfoAgent'}`)) || {}
      value.cmpCode = this.editFormData.cmpCode || userInfo.cmpId
      value.cmpName = this.editFormData.cmpName || userInfo.orgName
      compactServiceSaveAndUpdata(value, () => {
        // 函数里面加上上判断要不要在当前业的   如果是修改就当前业  不是修改就第一页
        this.getdata(value.compactId)
        this.$message.success('成功！')
        this.$refs.dialog.editFormVisible = false
      })
    }

  }
}
</script>

<style scoped>
.cmpNameSearch {
  width: 100%;
}

.marginTop {
  padding-top: 18px;
}
</style>
